.Input {
	--color: var(--dark-color);
	--placeholder-color: var(--dark-grey-color);
	cursor: pointer;
	position: relative;
	width: 100%;
	min-height: 2.8em;
	color: var(--color);

	&_disabled {
		cursor: initial;
		opacity: .5;
	}

	&__field {
		border-bottom: 1px solid var(--dark-color);
		height: 1.8em;
		position: relative;
		width: 100%;
	}

	&__label {
		cursor: inherit;
		display: block;
		font-weight: 300;
		height: 100%;
		left: 0;
		padding-top: .1em;
		position: absolute;
		top: 0;
		transition: .25s;
		width: 100%;
		z-index: 1;
	}
	&__input {
		bottom: 0;
		color: var(--color);
		cursor: inherit;
		display: block;
		font-size: 26px;
		height: 66%;
		left: 0;
		outline: none;
		position: absolute;
		width: 100%;
		z-index: 2;

		&::placeholder {
			transition: color .15s;
			color: transparent;
		}
	}
	&__note {
		color: var(--dark-grey-color);
		font-size: 48%;
		margin-top: .5em;
		opacity: 0;
		transition: opacity .15s;
		min-height: 1.5em;

		&_show {
			opacity: 1;
		}
		&_error {
			color: var(--danger-color);
			font-family: var(--main-font);
		}
	}


	&_focused {
		.Input__input {
			&::placeholder {
				color: var(--placeholder-color);
			}
		}
		.Input__label {
			font-size: 50%;
		}
	}
}