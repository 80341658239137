.Rate {
	align-items: center;
	background-color: var(--light-color);
	border-radius: 15px;
	outline: 4px solid var(--grey-color);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	display: grid;
	grid-template-columns: 60px 1fr 60px;
	padding: 56px;
	position: relative;
	user-select: none;
	outline-offset: -4px;
	overflow: hidden;

	&__main {
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin: auto;
		max-width: 720px;
		text-align: center;
	}

	&__title {
		align-items: center;
		display: flex;
		flex-direction: column;
		font-size: 24px;
		gap: 8px;
		justify-content: center;
		text-transform: uppercase;

		&:after {
			background-color: var(--grey-color);
			content: '';
			display: block;
			height: 1px;
			max-width: 340px;
			width: 100%;
		}
	}

	&__price {
		font-family: var(--accent-font);
		font-weight: 700;
		font-size: 32px;
	}

	&__note {
		font-size: 20px;
		line-height: 1.28em;
	}

	&_hot {
		&:before {
			background-image: url('./../../../public/images/hot-label.svg');
			background-position: top right;
			background-repeat: no-repeat;
			background-size: contain;
			content: '';
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			height: 82px;
			width: 82px;
			z-index: 1;
		}
	}

	&_disabled {
		cursor: inherit;
		opacity: .6;
	}
}