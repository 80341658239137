.MasterCard {
	background-color: var(--light-color);
	border-radius: 30px;
	box-shadow: 0 4px 4px rgba(4, 4, 8, 0.25);
	cursor: pointer;
	display: flex;
	font-size: 24px;
	gap: 22px;
	padding: 17px;

	&__avatar {
		background-color: var(--soft-color);
		border-radius: 50%;
		box-shadow: 0 4px 4px rgba(4, 4, 8, 0.25);
		display: block;
		height: 110px;
		overflow: hidden;
		width: 110px;

		img {
			height: 100%;
			width: 100%;
		}
	}

	&__header {
		display: flex;
		gap: 12px;
		font-size: 28px;
	}
	&__name {
		font-weight: 700;
	}

	&__main {
		display: flex;
		flex-direction: column;
		gap: 12px;
		justify-content: center;
		padding-bottom: 2px;
	}

	&__desc {
		display: grid;
		grid-template-columns: 2fr 1fr;
	}
}