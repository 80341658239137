.UserLogin {
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 22px;
	max-width: 100%;
	padding: 16px;
	max-width: 480px;
	width: 100%;

	h1 {
		text-align: center;
		font-size: 26px;
		font-weight: 400;
		margin-bottom: .24em;
	}
}