#Header {
	margin-bottom: 32px;

	.container {
		align-items: center;
		display: flex;
		justify-content: center;
		a {
			display: block;
		}
	}
}