.Studio {
	// display: grid;
	// grid-template-rows: 1fr 220px;
	overflow: hidden;
	flex-grow: 1;
	position: relative;
	
	display: flex;
	flex-direction: column;
	
	// display: grid;
	// grid-template-rows: auto 1rem 220px;

	&__panel {
		height: 220px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		z-index: 10;
		background-color: var(--dark-color);
	}
}