.note-screen {
	background-color: steelblue;
	font-family: Arial, Helvetica, sans-serif;
	color: white;
	font-size: 22px;
	min-height: 100vh;
	padding-top: 1rem;
	width: 100%;

	h1, p {
		margin-bottom: .35em;
	}
}