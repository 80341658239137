.ClientInfo {
	display: flex;
	flex-direction: column;
	font-size: 30px;
	font-weight: 300;
	justify-content: center;
	letter-spacing: 0.01em;
	padding-bottom: .25em;
	
	> div {
		border-bottom: 1px solid var(--light-color);
		padding-bottom: .75em;
	}
	> div + div {
		margin-top: 1.25em;
	}
}