.Tabber {

	&-tabs {
		display: flex;
		gap: 32px;
		margin: 32px 0;
	}

	&-tab {
		font-size: 28px;
		padding: .5em 1px;
		cursor: pointer;
		border-bottom: 3px solid transparent;

		&:hover {
			opacity: .75;
		}

		&_active {
			font-weight: 600;
			border-color: var(--dark-color);
		}
	}
}