@font-face {
	font-family: 'Sansation';
	src: url('./../../public/fonts/sansation/Sansation-Light.ttf') format('truetype');
	font-weight: 300;
}
@font-face {
	font-family: 'Sansation';
	src: url('./../../public/fonts/sansation/Sansation-Regular.ttf') format('truetype');
	font-weight: 400;
}
@font-face {
	font-family: 'Sansation';
	src: url('./../../public/fonts/sansation/Sansation-Bold.ttf') format('truetype');
	font-weight: 700;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./../../public/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
}
@font-face {
	font-family: 'Montserrat';
	src: url('./../../public/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
}