.Btn {
	--back-color: var(--dark-color);
	background-color: var(--back-color);
	border-radius: 13px;
	color: var(--light-color);
	font-size: 22px;
	font-weight: 700;
	padding: 1em 4.25em;
	transition: .1s;

	&:disabled {
		background-color: var(--dark-grey-color);
		opacity: .8;
	}

	&:not(:disabled) {
		cursor: pointer;

		&:hover {
			background-color: var(--dark-light-color);
		}
	}

	&_light {
		--back-color: var(--grey-color);
		color: var(--dark-color);
		padding: 1.4em 2em;
		text-transform: uppercase;
		font-size: 16px;

		&:not(:disabled):hover {
			background-color: var(--dark-light-color);
			color: var(--dark-grey-color);
		}

	}

	&_glass,
	&_glass-wide {
		--back-color: transperent;
		border: 1px solid var(--light-color);
		color: var(--light-color);
		font-size: 16px;

		&:not(:disabled):hover {
			background-color: rgba(255, 255, 255, 0.199);
		}

	}

	&_glass {
		padding: 1.3em 1.9em;
	}

	&_glass-wide {
		padding: 1.3em 3.6em;
	}

}