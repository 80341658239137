#RatesLayout {
	background-image: url('./../../../public/images/ink.jpg');
	background-position: top right;
	background-repeat: no-repeat;
	background-size: 464px;
	padding-bottom: 64px;
	padding-top: 56px;

	@media only screen and (max-width: 1720px) {
		background-image: initial;
	}
}