.SimpleFormModule {
	display: flex;
	flex-direction: column;
	gap: 18px;

	textarea {
		border: 1px solid var(--grey-color);
		padding: 1em;
		border-radius: 12px;
		background-color: var(--grey-color);
		width: 100%;
		resize: vertical;
		min-height: 160px;
		max-height: 520px;
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}
}