.LightFormModule {
	--stroke-color: var(--light-color);
	color: var(--light-color);
	display: flex;
	flex-direction: column;
	font-size: 36px;
	gap: 1.5em;
	margin-bottom: .85vh;
	width: 410px;

	a {
		color: inherit;
	}

	.Btn {
		border: 1px solid var(--light-color);
	}
	.Input {
		--color: var(--light-color);
		--placeholder-color: var(--grey-color);
	}
	.Input__field {
		border-bottom: 1px solid var(--stroke-color);
	}

	> header,
	> section,
	> footer {
		display: flex;
		flex-direction: column;
		gap: 1em;
	}

	> footer {
		text-align: center;

		a {
			font-size: 22px;
		}
	}

	.Eye {
		filter: invert(1);
		opacity: .5;
	}

	.error {
		background: var(--danger-color);
		color: var(--light-color);
		font-size: 22px;
		margin: initial;
		padding: .4em .6em;
		border-radius: 10px;
	}
}