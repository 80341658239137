.MegaHeader {
	--gradient-back: linear-gradient(0deg, rgba(103,102,100,1) 0%, rgba(145,142,137,1) 100%);
	align-items: center;
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
	border-color: #979797;
	border-radius: 0 0 173px 0;
	border-style: solid;
	border-width: 0 1px 1px 0;
	box-shadow: 5px 5px 10px  rgba(0, 0, 0, 0.25);
	color: var(--light-color);
	display: flex;
	flex-direction: column;
	height: 416px;
	position: relative;
	width: 100%;

	.container {
		width: 100%;
	}

	h1 {
		font-family: var(--accent-font);
		font-size: 78px;
		font-weight: 700;
		line-height: 1.25em;
		margin-top: .1em;
		max-width: 60vw;
		text-transform: uppercase;
	}

	&__actions {
		position: absolute;
		left: 0;
		top: 32px;
	}
	&_tools {
		background-image:
			url('./../../../public/images/tools.png'),
			var(--gradient-back);
		background-position: bottom right 60px, center;
	}
	&_chair {
		background-image:
			url('./../../../public/images/chair.png'),
			var(--gradient-back);
		background-position: right;
		background-repeat: no-repeat;
	}
	&_material {
		background-image:
			url('./../../../public/images/material.jpg'),
			var(--gradient-back);
		background-position: center;
		background-size: cover;

		h1 {
			text-align: center;
		}
	}
}