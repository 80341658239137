.StudioControll {
	position: absolute;
	top: 1.25em;
	left: 0;
	width: 100%;

	.container {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}