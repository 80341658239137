.StudioCamera {
	background-color: black;
	border-color: var(--dark-light-color);
	border-style: solid;
	border-width: 1px 0;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	canvas {
		outline: 2px solid lime;
	}

	#main-video {
		height: 100%;
		width: 100%;
	}
	.BtnSet {
		position: absolute;
	}

	// video {
	// 	height: 100%;
	// 	width: auto;
	// 	transform: rotate(-90deg);
	// }
}