.ClientInfoTile {
	align-items: center;
	background-color: var(--soft-color);
	background-image:
		url('./../../../public/images/chair.png'),
		linear-gradient(0deg, #646361, #928F8A);
	background-position: -192% 0, left top;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 35px;
	border: 1px solid var(--dark-color);
	color: var(--light-color);
	cursor: pointer;
	display: flex;
	font-family: var(--accent-font);
	height: 100%;
	padding: 48px;
	text-decoration: none;
	width: 100%;
}