.Stars {
	--star-size: 25px;
	display: flex;
	gap: 4px;
	margin-top: 2px;

	&__large {
		--star-size: 34px;
	}
}
.Star {
	background-image: url("./../../../public/icons/star.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: var(--star-size);
	height: var(--star-size);
	width: var(--star-size);
	transition: opacity .12s;

	&_light {
		// background-image: url("./../../../public/icons/star-light.svg");
		opacity: .2;
	}
}