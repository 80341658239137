.Nail {
	background-color: var(--grey-color);
	border-radius: 8px;
	color: var(--dark-color);
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-family: var(--main-font);
	font-weight: 400;
	gap: 16px;
	height: 318px;
	margin: 0 30px;
	padding-top: 42px;
	position: relative;
	transform: translateY(57%);
	transition: transform .3s;
	width: 72px;

	&::after {
		content: '';
		height: 140%;
		left: -20%;
		position: absolute;
		top: -50%;
		width: 140%;
	}

	&__preview {
		background-color: #f5f5f5;
		background-position: 18% 28%;
		background-repeat: no-repeat;
		background-size: 55% 80%;
		border-radius: 4px 4px 50% 50%;
		bottom: calc(100% - 18px);
		box-shadow: 1px 1px 16px rgba(1, 3, 12, 0.5);
		height: 164px;
		position: absolute;
		width: 100%;
	}

	&__code {
		background-color: coral;
		padding: 4px 8px;
		text-align: center;
		align-self: center;
		text-transform: uppercase;
		outline: 1px solid rgba(0, 0, 0, 0.075);
		user-select: none;
	}
	
	&__name {
		transform: rotate(90deg);
		transform-origin: 50%;
		margin-top: 22px;
		white-space: nowrap;
		letter-spacing: 0.03em;
		user-select: none;
	}

	&_active {
		transform: translateY(0);
	}
}
