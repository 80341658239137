.ClientMegaHeader {
	--gradient-back: linear-gradient(0deg, rgba(103,102,100,1) 0%, rgba(145,142,137,1) 100%);
	align-items: center;
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
	border-color: #979797;
	border-radius: 0 0 173px 0;
	border-style: solid;
	border-width: 0 1px 1px 0;
	box-shadow: 5px 5px 10px  rgba(0, 0, 0, 0.25);
	color: var(--light-color);
	display: flex;
	flex-direction: column;
	height: 416px;
	position: relative;
	width: 100%;

	background-image:
		url('./../../../public/images/chair.png'),
		var(--gradient-back);

	&__actions {
		position: absolute;
		left: 0;
		top: 32px;
	}

	.container {
		align-items: self-start;
		display: flex;
		flex-direction: column;
		gap: 32px;
		height: 100%;
		justify-content: flex-end;
		padding-bottom: 38px;
		width: 100%;
	}

	.ClientInfo {
		max-width: 38vw;
		width: 100%;
	}
}