.MegaCheckbox {
	background-color: var(--light-color);
	background-image: url('./../../../public/icons/check.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 0;
	border-radius: 5px;
	border: 1px solid var(--grey-color);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
	height: 50px;
	transition: background-color .1s, background-size 0s;
	user-select: none;
	width: 50px;

	&_checked {
		--back-color: var(--positive-color);
		background-size: 66%;
		background-color: var(--positive-color);
		border-color: var(--back-color);
		box-shadow: initial;
	}

	&_focused {
		outline: 2px solid var(--dark-grey-color);
	}

	input[type="checkbox"] {
		opacity: 0;
	}
}