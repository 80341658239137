.MegaBtn {
	background-color: var(--soft-color);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 35px;
	border: 1px solid var(--dark-color);
	color: var(--text-color);
	font-size: 30px;
	height: 100%;
	padding: 1em;
	text-decoration: none;
	width: 100%;

	&_material {
		align-items: center;
		background-image:
			url('./../../../public/images/material-light.jpg');
		background-position: center;
		background-size: cover;
		display: flex;
		text-align: center;
		padding-left: 1.75em;
	}

	&_2 {
		background-position: right;
		background-size: contain;
		grid-column-end: 3;
		grid-column-start: 1;
		display: flex;
		align-items: center;
		padding-left: 1.75em;
	}

	&_chair {
		color: var(--soft-color);
		background-image:
			url('./../../../public/images/chair.png'),
			linear-gradient(0deg, #646361, #928F8A);
	}
	&_phone {
		background-image:
			url('./../../../public/images/phone.jpg');
		padding-top: 4.2em;

		> span {
			display: block;
			max-width: 8em;
		}
	}
	&_fingers {
		display: flex;
		justify-content: flex-end;
		padding-top: 2.25em;
		background-image:
			url('./../../../public/images/fingers.jpg');
	}
	&_tools {
		background-image:
			url('./../../../public/images/tools.png');
		padding-top: 2.25em;
	}
	&_games {
		background-image:
			url('./../../../public/images/games.jpg');
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}
}