.ActionBtn {
	background-color: var(--darker-color);
	border-radius: 10px;
	border: 1px solid var(--dark-grey-color);
	color: var(--light-color);
	cursor: pointer;
	text-transform: uppercase;

	&:not(:disabled):hover {
		background-color: var(--dark-light-color);
	}
}