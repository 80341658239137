.PhotoBtn {
	align-items: center;
	background-color: var(--dark-color);
	border-radius: 50%;
	border: 5px solid var(--light-color);
	cursor: pointer;
	display: flex;
	height: 104px;
	justify-content: center;
	margin: auto;
	width: 104px;

	&:disabled {
		opacity: .3;
	}

	&:after {
		background-color: var(--light-color);
		border-radius: 50%;
		content: '';
		display: block;
		height: 88%;
		transition: opacity .1s;
		width: 88%;
	}

	&:hover {
		&:after {
			opacity: .66;
		}
	}
}