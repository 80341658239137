.LoginForm {
	display: flex;
	flex-direction: column;
	font-size: 36px;
	gap: .75em;
	margin-bottom: 14px;
	max-width: 1160px;

	fieldset {
		display: flex;
		gap: 1.25em;
	}
}