.container {
	margin: auto;
	max-width: 1720px;
	padding: 0 64px;

	@media only screen and (max-width: 1024px) {
		padding: 0 48px;
	}
	@media only screen and (max-width: 720px) {
		padding: 0 32px;
	}
	@media only screen and (max-width: 480px) {
		padding: 0 22px;
	}
}

.block {
	margin: 48px 0;
}

.section-header {
	margin-bottom: 1.75em;

	h2 {
		margin-bottom: .25em;
	}
}