.BackBtn {
	// background-color: rgba(31, 31, 31, 0.075);
	align-items: center;
	background-image: url("./../../../public/icons/black-arrow.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 43px;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	height: 43px;
	justify-content: center;
	width: 43px;
	transition: opacity .1s;

	&:before {
		border-radius: 50%;
		content: '';
		display: block;
		height: 70px;
		position: absolute;
		width:  70px;
	}

	&:hover {
		opacity: .8;
	}
}
