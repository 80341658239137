.MasterDetailHeader {
	--gradient-back: linear-gradient(0deg, rgba(103,102,100,1) 0%, rgba(145,142,137,1) 100%);
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: var(--gradient-back);
	border-color: #979797;
	border-radius: 0 0 173px 0;
	border-style: solid;
	border-width: 0 1px 1px 0;
	box-shadow: 5px 5px 10px  rgba(0, 0, 0, 0.25);
	color: var(--light-color);
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	padding-bottom: 32px;
	height: 416px;
	position: relative;
	width: 100%;
	overflow: hidden;

	.container {
		margin: initial;
		width: 100%;
	}

	&__actions {
		position: absolute;
		left: 0;
		top: 32px;
	}

	&__card {
		align-items: center;
		display: grid;
		gap: 80px;
		grid-template-columns: 3fr 2fr;
	}

	&__list {
		font-weight: 300;
		font-size: 24px;
		letter-spacing: 0.01em;
		> div {
			padding-top: 24px;
		}
		> div + div {
			border-top: 1px solid var(--light-color);
			margin-top: 24px;
		}
	}

	&__avatar {
		bottom: -6px;
		position: absolute;
		right: -22px;
		img {
			border-radius: 50%;
			overflow: hidden;
			background-color: var(--light-color);
			height: 424px;
			width: 424px;
		}
	}
}