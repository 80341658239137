.StudioConstructor {
	flex-grow: 1;
	height: 220px;
	width: 100%;

	&_btns {
		display: grid;
		flex-grow: 1;
		gap: 1em;
		grid-template-columns: 1fr 1fr;
		height: 100%;
		padding: 1.25em 1.75em;
		width: 100%;
	}
}