.Eye {
	background-image: url('./../../../public/icons/eye.svg');
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
	height: .7em;
	opacity: .33;
	position: absolute;
	right: 0;
	top: 22%;
	transition: opacity .15s;
	width: .7em;
	z-index: 3;

	&:hover {
		opacity: .75;
	}
}