@import './fonts.scss';
@import './layout.scss';

:root {
	--dark-color: #000000;
	--darker-color: #1F1F1F;
	--dark-light-color: #333338;
	--back-color: #f3f3f3;
	--dark-grey-color: #a5a5a8;
	--grey-color: #d9d9d9;
	--light-color: #ffffff;
	--soft-color: #dcdddf;
	--positive-color: #15Af5d;
	// --danger-color: crimson;
	--danger-color: #ff4a36;

	--accent-font: sansation, roboto, arial;
	--main-font: montserrat, serif, roboto, arial;
}

* {
	background: none;
	border: none;
	box-sizing: border-box;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	margin: 0;
	padding: 0;
	// transition: .25s;
}

body {
	background-color: var(--back-color);
}

body,
#root,
#App {
	min-height: 100vh;
	width: 100%;
}

#root {
	font-family: var(--accent-font);
	font-size: 22px;
	font-weight: 400;
}

pre {
	background-color: #0000000f;
	border: 1px dotted grey;
	font-family: monospace;
	font-size: 16px;
	// max-width: 640px;
	padding: 0.2em 0.4em;
	text-align: left;
	white-space: pre-wrap;
	word-wrap: break-word;
}

img {
	display: block;
}

h2 {
	font-size: 36px;
	font-weight: 400;
}

.error {
	font-size: 24px;
	font-family: var(--main-font);
	font-weight: 400;
	color: var(--danger-color);
	margin: .14em 0;
}

.link {
	text-decoration: underline;
	color: var(--dark-light-color);
	cursor: pointer;
	transition: color .1s;

	&:hover {
		color: var(--dark-color);
	}

	&_disabled {
		cursor: inherit;
	}
}

.auth-form {
	align-items: center;
	background-color: #010000;
	background-position: left 100px center;
	background-repeat: no-repeat;
	background-size: cover;
	color: var(--light-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
	width: 100%;
}