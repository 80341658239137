.pagi {
	display: flex;
	font-size: 26px;
	gap: 12px;
	justify-content: center;
	list-style: none;
	margin: 2em 0;
	padding: initial;
	user-select: none;

	> li {
		border: 1px solid var(--grey-color);
		border-radius: 7px;

		a {
			align-items: center;
			cursor: pointer;
			display: flex;
			height: 2.5em;
			justify-content: center;
			text-align: center;
			width: 2.5em;
		}

		&:hover {
			background-color: var(--soft-color);
		}

		&.selected {
			background-color: var(--grey-color);
		}
	}
}