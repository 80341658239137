.Gallery {
	display: grid;
	gap: 20px;
	grid-template-columns: 1fr 1fr;
	margin: 20px 0 40px;

	&__item {
		background-color: var(--soft-color);
		border-radius: 35px;
		box-shadow: 4px 4px 8px rgba(5, 6, 17, 0.25);
		height: 420px;
	}
}