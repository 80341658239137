.Rates {
	padding-top: 92px;
	font-size: 22px;

	> .container {
		display: flex;
		flex-direction: column;
		gap: 74px;
	}

	&_ink {
		padding-top: 34px;

		.section-header {
			text-align: center;
		}
	}
}